<template>
    <div class="catalog" style="margin-top: 30px;margin-bottom: 40vh;">
      <div class="container">
        <div class="row" >
          <div class="col-12">
            <div class="row row--grid" style="margin-top: 20px;">
              <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in searchData.slice(0,12)" :key="publish.id">
                <!-- <div class="profile-card">
                  <div class="card">
                    <a @click="GetContent(sear)" class="card__cover profile-card-test" style="height: 220px;">
                      <img v-lazy="sear.img" alt="">
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <div class="shotal">
                          <h3 class="card__title" >
                            <a style="color: #fff !important;font-size: 13px;" @click="GetContent(sear)">{{ sear.title_ar }}</a>
                          </h3>
                          <ul style="justify-content: center;" class="card__list">
                            <li style="margin-left: 0;">{{ sear.name_ar }}</li>
                             <li></li>
                          </ul>
                        </div>
                    </a>
                  </div>
                </div> -->
                <div id="busicard" @click="GetContent(publish)">
                                          <div class="menu"  @click="GetContent(publish)">
                                            <div class="movembar">
                                              <h4 class="texto"><svg class="svg-icon"
                                              style="width: 20px;height:20px;margin-left:5px" viewBox="0 0 20 20">
                                              <path fill="#fff" d="M16.227,16.672c0,0.49-0.396,0.889-0.889,0.889H2.883c-0.491,0-0.889-0.398-0.889-0.889V5.107c0-0.491,0.398-0.89,0.889-0.89h7.562V3.328H2.883c-0.982,0-1.779,0.796-1.779,1.779v11.565c0,0.982,0.796,1.779,1.779,1.779h12.455c0.982,0,1.779-0.797,1.779-1.779V10h-0.891V16.672z M15.338,1.549c-1.965,0-3.559,1.593-3.559,3.558c0,1.966,1.594,3.558,3.559,3.558s3.559-1.592,3.559-3.558C18.896,3.142,17.303,1.549,15.338,1.549z M15.338,7.776c-1.475,0-2.668-1.195-2.668-2.669c0-1.474,1.193-2.669,2.668-2.669s2.668,1.195,2.668,2.669C18.006,6.581,16.812,7.776,15.338,7.776z"></path>

                                            </svg>{{ publish.file_duration }} </h4>
                                              <!-- <a href="#" class="mbut"><span class="card__rating">
                                                                                      {{ publish.file_duration }}
                                                                                    </span></a> -->
                                            </div>
                                            <!-- <div class="movegear">
                                                <a href="#"><div class="gear"></div></a>
                                            </div> -->
                                          </div>
                                          <div class="user pointer"  @click="GetContent(publish)">
                                            <img class="img2" v-lazy="publish.img" >
                                          </div>
                                          
                                        
                                          <div class="bfollow pointer">
                                            <a @click="GetContent(publish)">مشاهدة</a>
                                          </div>
                                          <div class="content pointer">
                                            <p class="one-line-text">{{ publish.title_ar }}</p>
                                            <h6 style="margin-top: -1vh;font-size: 85%;"> {{ publish.sub_cat_name }} </h6>
                                          </div>
                                          
                                        </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="searchData == null">
            <div class="row row--grid" style="margin-top:40px;justify-content: center;">
              <div class="search-message-empty-container" dir="ltr" style="color:#0a6069;padding-right:15px;margin-bottom:calc(50vh - 25%);font-size:75%;font-family:'Cairo', sans-serif;">
                <span class="search-message-empty-decal">
                <span class="search-message-empty-decal-eyes">:</span>
                <span>(</span>
                </span>
                <h2 class="search-message-empty-message">
                  عذرا لم نجد نتائج بحث  
                </h2>
              </div>
            </div>
          </div>
        </div>		
      </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useCookie } from 'vue-cookie-next'
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useRouter } from "vue-router"
export default {
   name: 'Searchs',
    props: {
       search_id: String,
    },
    
    setup(props) {
      const cookie = useCookie()
      const router = useRouter();
      const searchData = ref([]);
      const GetSearch = async () => {
          await HTTP.get(`GetSearchContent.php?search=`+ props.search_id).then((res) => {
              searchData.value = res.data.Content;
           })
      }
      onMounted(() => {
          GetSearch();
      });
      // const GetContent = (sear) => {
      //   if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("minutes") > 0 ) {
      //     router.push({ name: "Content", params: { content_id: sear.id } });
      //   } else {
      //     router.push({ name: "Trailer" });
      //   }
      // };
      const GetContent = (sear) => {
      cookie.setCookie('Content_id', sear.id)
      cookie.setCookie('cat_id', sear.cat_id);
      // if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
      //     HTTP.post(`CheckLogin.php?username=`+ cookie.getCookie("msisdn")+`&serviceId=2222&operatorID=2`).then((res) => {
      //         if (res.data.MINTUES > 0) {
                router.push({ name: "Content", params: { Content_id: sear.id } });
      //         } else {
      //           router.push("/Login");
      //         }
      //     });
      // } else {
      //     cookie.setCookie('id', sear.id);
      //     router.push({ name: "Landing" });
      // }
    };
      return {
        searchData,
        GetContent
      };
    },
  // methods: {
  //   GetContent: function (sear) {
  //     this.$cookie.setCookie('Content_id', sear.id);
  //     this.$cookie.setCookie('cat_id', sear.cat_id);
  //     if (this.$cookie.isCookieAvailable("minutes") && this.$cookie.isCookieAvailable("mdn")) {
  //       axios.post(
  //           "CheckUserCategory.php?msisdn="+this.$cookie.getCookie("mdn")+"&product_code="+sear.cat_id
  //         ).then((response) => {
  //             if (response.data.MINTUES > 0) {
  //               this.$router.push("/Content");
  //             } else {
  //               this.$router.push("/Trailer");
  //             }
  //         });
         
  //     } else {
  //       this.$router.push("/Trailer");
  //     }
  //   },
  // },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
.search-message-empty-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4.5em;
}

.search-message-empty-decal {
  margin-right: 2rem;
  font-size: 6rem;
  transform: rotate(90deg);
}

.search-message-empty-message {
  font-size: 1.5em;
  text-rendering: optimizeLegibility;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<style scoped>

  .img2 {
    height: auto;
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  #busicard {
    position: relative;
    background: linear-gradient(to left, #00D2C8, #179C8C);
    border-radius: 35px;
    height: 232px;
    max-width: 440px;
    margin: 10px auto;
  }
  #busicard h1 {
    color: #FAEAFF;
  }
  .movembar {
    display: flex;
      flex-direction: row;
      justify-content: baseline;
      align-items: flex-start;
  }
  .movegear {
    display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: flex-start;
    margin-top: -2px;
  }
  .mbar {
    width: 10px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    margin: 17px 35px 0 36px;
  }
  .mbar:before {
    content: '';
    width: 12px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    top: 0px;
    left: 0;
  }
  .mbar:after {
    content: '';
    width: 18px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    top: 5px;
    left: 0;
  }
  .mbut:hover .mbar, .mbut:hover .mbar:before, .mbut:hover .mbar:after {
    background: #2F4858;
  }
  .user {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
          margin-top: -24px;
  }
  .user img {
    border-radius: 35px 35px 0 0;
  width: 100%;
  /* border: 4px solid #FAEAFF; */
  /* margin-top: 1vh; */
  height: 130px;
  }
  .user img:hover{
    filter: saturate(0);
  -webkit-filter: saturate(0);
  -moz-filter: saturate(0);
  }
  .gear {
    background-image:
      radial-gradient(at center center, #00D2C8 23%, #eee 25%, #FAEAFF 57%, transparent 53%),
      linear-gradient(0deg, transparent 43%, #eee 40%, #FAEAFF 60%, transparent 60%),
      linear-gradient(45deg, transparent 43%, #eee 43%, #FAEAFF 57%, transparent 57%),
      linear-gradient(90deg, transparent 40%, #eee 40%, #FAEAFF 60%, transparent 60%),
      linear-gradient(135deg, transparent 43%, #eee 43%, #FAEAFF 57%, transparent 57%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin: 17px 35px 0 36px;
  }
  .gear:hover {
    background-image:
      radial-gradient(at center center, #00D2C8 23%, #242424 25%, #2F4858 57%, transparent 53%),
      linear-gradient(0deg, transparent 43%, #242424 40%, #2F4858 60%, transparent 60%),
      linear-gradient(45deg, transparent 43%, #242424 43%, #2F4858 57%, transparent 57%),
      linear-gradient(90deg, transparent 40%, #242424 40%, #2F4858 60%, transparent 60%),
      linear-gradient(135deg, transparent 43%, #242424 43%, #2F4858 57%, transparent 57%);
  }
  .info li {
    color: #eee;
    font-size: 0.8em;
    display: inline-block;
    margin: 0 6px;
  }
  .hrtop {
    display: inline-block;
    background-color: #FAEAFF;
    width: 2px;
    height: 22px;
    margin: -2px auto;
  }
  .contacts {
    min-height: 100px;
  }
  .contacts i {
    display: inline-block;
    margin: 20px 0;
  }
  .contacts a {
    margin: 0 6px;
  }
  .bfollow {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    /*! margin: 25px 0 0 0; */
  }
  .bfollow a {
    position: absolute;
    box-shadow: 0 0 15px 1px #000;
    color: #FAEAFF;
    background-color: #1F937D;
    border-radius: 30px;
    padding: 7px 49px;
    transition: 350ms;
      -webkit-transition: all .3s;
  }
  .bfollow a:hover {
    font-weight: bold;
    color: #2F4858;
    background-color: #FAEAFF;
    padding: 8px 56px;
    transition: 350ms;
      -webkit-transition: all .3s;
  }
  .content {
    color: #FAEAFF;
    background: linear-gradient(to bottom, #2F4858, #2C687C);
    border-radius: 0 0 30px 30px;
    min-height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content p {
    min-width: 100%;
  border-top: none;
  text-align: center;
  font-family: "Noto Kufi Arabic";
  font-size: 16px;
  margin-top: 20px;
  }
  .lock {
    width: 26px;
    height: 16px;
    background: #FAEAFF;
    position: relative;
    border-radius: 4px;
    top: 30px;
  }
  .lock:before {
    content: '';
    width: 6px;
    height: 6px;
    background: transparent;
    border: 6px solid #FAEAFF;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    position: absolute;
    bottom: 16px;
    right: 4px;
  }
  a .fa {
    font-size: 36px;
    transition: 500ms;
  }
  a:hover .fa {
    color: #2F4858;
    transition: 500ms;
  }
  a .fa-twitter-square, a .fa-instagram, a .fa-facebook-square {
    color: #FAEAFF;
  }
  
  .texto{
    margin-right: 15px;
  text-align: right;
  font-size: 15px;
  background: #1F937D;
  border-radius: 0 10px 10px 15px;
  padding: 0 16px;
  z-index: 9;
  font-weight: 900;
  }
  
  </style>